import config from '@/config';
import { getJson } from '@/helpers/ajax';
import Fuse, { type Expression } from 'fuse.js';
import { QueryObserverOptions, useQuery } from 'react-query';
import { EegJobSearchData, JobSearchAPIResponse } from './types';

const getJobs = async (): Promise<EegJobSearchData[]> => {
  const endpoint = `${config.apiBaseUrl}/eeg/job-search`;

  const { data } = await getJson<JobSearchAPIResponse>(endpoint);

  return data;
};

const queryFn = () => async () => {
  const jobs = (await getJobs().catch(() => undefined)) ?? [];

  if (jobs.length <= 0) {
    throw new Error(`Keine Jobs gefunden`);
  }

  return jobs;
};

const useEegJobSearch = (
  searchTerm?: string,
  jobEmploymentType?: string,
  workplace?: string,
  queryConfig: QueryObserverOptions<EegJobSearchData[]> = {}
) => {
  const queryResult = useQuery<EegJobSearchData[]>(
    ['eegJobSearch'],
    queryFn(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      staleTime: Infinity,
      retry: 1,
      keepPreviousData: true,
      ...queryConfig,
    }
  );

  const allJobs = queryResult.data ?? [];

  const isFiltered = !!jobEmploymentType || !!searchTerm || !!workplace;

  const searchArray = [
    workplace
      ? {
          $or: [
            {
              jobWorkplaceZipcode: workplace,
            },
            {
              jobWorkplace: workplace,
            },
          ],
        }
      : undefined,
    jobEmploymentType ? { jobEmploymentType } : undefined,
    searchTerm
      ? {
          $or: [
            {
              jobTitle: searchTerm,
            },
            {
              jobSummary: searchTerm,
            },
            {
              jobResponsibilities: searchTerm,
            },
            {
              jobRequirements: searchTerm,
            },
            {
              jobBenefits: searchTerm,
            },
            {
              jobFulltext: searchTerm,
            },

            {
              jobIndustry: searchTerm,
            },
            {
              jobCategory: searchTerm,
            },
            {
              jobEducationRequirements: searchTerm,
            },
          ],
        }
      : undefined,
  ].filter(Boolean) as Expression[];

  const filteredJobs = new Fuse(allJobs, {
    ignoreLocation: true,
    keys: [
      'jobTitle',
      'jobSummary',
      'jobResponsibilities',
      'jobRequirements',
      'jobBenefits',
      'jobFulltext',
      'jobIndustry',
      'jobCategory',
      'jobEducationRequirements',

      // custom...
      'jobWorkplace',
      'jobWorkplaceZipcode',
      'jobEmploymentType',
    ],
    threshold: 0.25,
    useExtendedSearch: true,
  })
    .search({
      $and: searchArray,
    })
    .map((result) => result.item);

  const data = isFiltered ? filteredJobs : allJobs;

  return {
    ...queryResult,
    jobs: allJobs,
    filteredJobs: data,
  };
};

export default useEegJobSearch;
