import type { SuggestionOption } from '../FormFieldSuggestion/interfaces';
import cars from './cars.json';

export const filterOptions = (value: string, options: SuggestionOption[]) => {
  if (!value) {
    return options;
  }

  return options.filter((option) =>
    option.value.toLowerCase().includes(value.toLowerCase())
  );
};

export const getBrandOptions = () => {
  return cars.map(({ brand }) => ({ label: brand, value: brand }));
};

export const getModelOptions = (brand: string) => {
  return (
    cars
      .find((car) => car.brand === brand)
      ?.models.map((model) => ({ label: model, value: model })) || []
  );
};

export const shouldBeSelected = (
  value: string,
  options: SuggestionOption[]
) => {
  const hasValidOption = options.some((option) => option.value === value);
  const filtredOption = filterOptions(value, options);

  return hasValidOption && filtredOption.length === 1;
};
