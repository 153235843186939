import type { TableCell, TableData } from '@uikit/components/Table/types';

function getHtmlContent(
  nodes: ChildNode[],
  start: number,
  end: number
): string {
  return nodes
    .slice(start, end)
    .map((node) => (node instanceof HTMLElement ? node.outerHTML : null))
    .filter(Boolean)
    .join('');
}

function extractTableData(table: HTMLTableElement): TableData {
  const head = Array.from(table.querySelectorAll('thead th')).map(
    (th) => th.textContent?.trim() || ''
  );

  const body = Array.from(table.querySelectorAll('tbody tr')).map((tr) =>
    Array.from(tr.querySelectorAll('th, td'))
      .map((el) => ({
        content: el.innerHTML.trim(),
        type: el.nodeName.toLowerCase(),
      }))
      .filter((el): el is TableCell => el.type === 'th' || el.type === 'td')
  );

  const caption = table.querySelector('caption')?.textContent?.trim() || '';

  return { head, body, ...(caption && { caption }) };
}

function removeTableFigures(doc: Document) {
  doc.querySelectorAll('figure').forEach((fig) => {
    const table = fig.querySelector('table');
    if (table) {
      fig.replaceWith(table);
    }
  });
}

export function parseTable(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  removeTableFigures(doc);

  const nodes = Array.from(doc.body.childNodes);
  const tables = Array.from(doc.querySelectorAll('table'));

  return tables.map((tableElement, index) => {
    const nodeIndex = nodes.indexOf(tableElement);
    const htmlBefore = index === 0 ? getHtmlContent(nodes, 0, nodeIndex) : '';
    const nextTableIndex = nodes.indexOf(tables[index + 1]);
    const htmlAfter = getHtmlContent(
      nodes,
      nodeIndex + 1,
      nextTableIndex === -1 ? nodes.length : nextTableIndex
    );
    const tableData = extractTableData(tableElement);

    return {
      htmlBefore,
      htmlAfter,
      tableData,
    };
  });
}
