import { logger } from '@/helpers/logger';
import type { Asset } from '@uikit/types';
import { useQuery } from 'react-query';

const fetchAsset = async (path: string | undefined) => {
  if (!path) {
    return null;
  }

  try {
    const response = await fetch(path);
    const data: object = await response.json();

    return data;
  } catch (error) {
    logger.error(`Failed to load lottie animation data from ${path}`, error);
    return null;
  }
};

export const useLottieAsset = (asset: Asset | undefined) => {
  return useQuery(
    ['lottie-asset', asset?.path],
    () => fetchAsset(asset?.path),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: !!asset?.path,
    }
  );
};
