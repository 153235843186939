import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import clsx from 'clsx';
import React, { useEffect, useId } from 'react';
import useKeyPress from '../../../hooks/useKeyPress/useKeyPress';
import useOnClickOutside from '../../../hooks/useOnClickOutside/useOnClickOutside';
import FormField from '../FormField/FormField';
import NativeSelect from '../NativeSelect/NativeSelect';
import styles from './FormFieldDropdown.module.scss';
import { FormFieldDropdownOption, FormFieldDropdownProps } from './interfaces';

const hasEventTargetWithName = ($event: KeyboardEvent, name: string) =>
  $event.target && ($event.target as HTMLElement).id === name;

const PAGE_KEYS_STEP_LENGTH = 10;

const FormFieldDropdown = ({
  options,
  label,
  onChange,
  onBlur,
  errorMessage,
  value,
  name,
  isBoxOnGradient = false,
  errorMessageHasRelativePosition,
  nativeSelectOnly = false,
}: FormFieldDropdownProps) => {
  const id = useId();
  const labelId = useId();

  const isMobile = useIsMobile(BreakPoint.XS);

  const ref = React.useRef<HTMLDivElement>(null);
  const optionListRef = React.useRef<HTMLUListElement>(null);
  const [showOptions, setShowOptions] = React.useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = React.useState(-1);
  const selectedOption = options.find((option) => option.value === value);

  const toggleShowOptions = React.useCallback(() => {
    setShowOptions((state) => !state);
  }, [setShowOptions]);

  const onChangeSelectedOption = React.useCallback(
    (option: FormFieldDropdownOption | null) => {
      setShowOptions(() => false);
      if (onChange) {
        onChange(option);
      }
    },
    [setShowOptions, onChange]
  );

  const handleOnChangeNativeSelect = (
    $event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const option =
      options.find((option) => option.value === $event.target.value) || null;
    onChangeSelectedOption(option);
  };

  const handleOnFocus = React.useCallback(
    () => toggleShowOptions(),
    [toggleShowOptions]
  );

  const handleOnClickOption = React.useCallback(
    (option: FormFieldDropdownOption) => () => {
      onChangeSelectedOption(option);
    },
    [onChangeSelectedOption]
  );

  const onPressArrow =
    (direction: 'up' | 'down') => ($event: KeyboardEvent) => {
      if (hasEventTargetWithName($event, name)) {
        $event.preventDefault();

        if (direction === 'up') {
          if (selectedOptionIndex === 0) {
            setSelectedOptionIndex(options.length - 1);
          }
          if (selectedOptionIndex > 0) {
            setSelectedOptionIndex(selectedOptionIndex - 1);
          }
          return;
        }

        if (direction === 'down') {
          if (!showOptions) {
            setShowOptions(() => true);
          } else if (selectedOptionIndex !== options.length - 1) {
            setSelectedOptionIndex(selectedOptionIndex + 1);
          } else {
            setSelectedOptionIndex(0);
          }

          return;
        }
      }
    };

  const onPressEnter = React.useCallback(
    ($event: KeyboardEvent) => {
      if (hasEventTargetWithName($event, name)) {
        $event.preventDefault();

        if (options.length) {
          if (!showOptions) {
            toggleShowOptions();
          } else if (options[selectedOptionIndex]) {
            onChangeSelectedOption(options[selectedOptionIndex]);
          }
        }
      }
    },
    [
      name,
      onChangeSelectedOption,
      options,
      selectedOptionIndex,
      showOptions,
      toggleShowOptions,
    ]
  );

  const onPressHome = ($event: KeyboardEvent) => {
    $event.preventDefault();
    setSelectedOptionIndex(0);
  };

  const onPressEnd = ($event: KeyboardEvent) => {
    $event.preventDefault();
    setSelectedOptionIndex(options.length - 1);
  };

  const onPressPageUp = ($event: KeyboardEvent) => {
    $event.preventDefault();

    if (selectedOptionIndex <= PAGE_KEYS_STEP_LENGTH) {
      setSelectedOptionIndex(0);
    } else {
      setSelectedOptionIndex(selectedOptionIndex - PAGE_KEYS_STEP_LENGTH);
    }
  };

  const onPressPageDown = ($event: KeyboardEvent) => {
    $event.preventDefault();

    if (options.length - selectedOptionIndex <= PAGE_KEYS_STEP_LENGTH) {
      setSelectedOptionIndex(options.length - 1);
    } else {
      setSelectedOptionIndex(selectedOptionIndex + PAGE_KEYS_STEP_LENGTH);
    }
  };
  const onPressTab = React.useCallback(
    ($event: KeyboardEvent) => {
      if (hasEventTargetWithName($event, name) && showOptions) {
        if (selectedOption) {
          setSelectedOptionIndex(options.indexOf(selectedOption));
        }
        toggleShowOptions();
      }
    },
    [name, options, selectedOption, showOptions, toggleShowOptions]
  );

  useKeyPress('Tab', { onKeyDown: onPressTab });
  useKeyPress('Escape', { onKeyDown: onPressTab });
  useKeyPress('ArrowUp', { onKeyDown: onPressArrow('up') });
  useKeyPress('ArrowDown', { onKeyDown: onPressArrow('down') });
  useKeyPress('Enter', { onKeyDown: onPressEnter });
  useKeyPress(' ', { onKeyDown: onPressEnter });
  useKeyPress('Home', { onKeyDown: onPressHome });
  useKeyPress('End', { onKeyDown: onPressEnd });
  useKeyPress('PageUp', { onKeyDown: onPressPageUp });
  useKeyPress('PageDown', { onKeyDown: onPressPageDown });

  useOnClickOutside(ref, () => {
    if (showOptions) {
      setShowOptions(() => false);
    }
  });

  useEffect(() => {
    if (optionListRef?.current) {
      const currentRef: HTMLElement = optionListRef.current;
      currentRef.scrollTop =
        currentRef.children[0].clientHeight * (selectedOptionIndex - 1);
    }
  }, [selectedOptionIndex]);

  return (
    <div
      aria-activedescendant={
        selectedOptionIndex === -1 ? undefined : `${id}${selectedOptionIndex}`
      }
      aria-controls={id}
      aria-expanded={showOptions}
      aria-haspopup="listbox"
      aria-owns={id}
      className={clsx(styles.base, { [styles.isOpen]: showOptions })}
      ref={ref}
      role="combobox"
      tabIndex={-1}
    >
      <FormField
        autoComplete="off"
        className={styles.formField}
        errorMessage={errorMessage}
        errorMessageHasRelativePosition={errorMessageHasRelativePosition}
        icon={'action/chevron-down'}
        id={name}
        isBoxOnGradient={isBoxOnGradient}
        label={label}
        labelId={labelId}
        name={name}
        onBlur={onBlur}
        onFocus={handleOnFocus}
        readOnly
        value={selectedOption?.label ?? ''}
      />

      {nativeSelectOnly || isMobile ? (
        <NativeSelect
          className={styles.nativeSelect}
          id={id}
          mobileOnly={false}
          name={name}
          onChange={handleOnChangeNativeSelect}
          options={options}
          selectedOption={selectedOption}
          ariaLabelledBy={labelId}
        />
      ) : (
        <ul
          aria-labelledby={labelId}
          className={clsx(styles.list, {
            [styles.isActive]: showOptions,
            [styles.withScrollbar]: options.length > 3,
          })}
          id={id}
          ref={optionListRef}
          role="listbox"
        >
          {options.map((option, index) => (
            <li
              aria-label={option.label}
              aria-selected={index === selectedOptionIndex}
              className={clsx(styles.listItem, {
                [styles.selected]: index === selectedOptionIndex,
              })}
              id={`${id}${index}`}
              onClick={handleOnClickOption(option)}
              role="option"
              key={option.value}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FormFieldDropdown;
