const FORM_API_BASE_URL = process.env.FORM_API_BASE_URL;

const submitFormApi = async <T = {}>({
  formId,
  formFields,
  controlFields,
  frcCaptchaSolution,
}: {
  formId: string;
  formFields: T;
  controlFields?: { [key: string]: unknown };
  frcCaptchaSolution?: string;
}): Promise<Response> => {
  const endpoint = `${window.FORM_API_BASE_URL || FORM_API_BASE_URL}/record`;
  const body = JSON.stringify({
    formId,
    formFields,
    controlFields,
    frcCaptchaSolution,
  });

  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
};

export default submitFormApi;
