import type { QCellsApprovalFormValues } from '@/components/Forms/QCellsApprovalForm/types';
import * as Yup from 'yup';
import {
  city,
  email,
  firstName,
  lastName,
  street,
  streetNumber,
  zipcode,
} from '../../../schemas';

const schema: Record<keyof QCellsApprovalFormValues, Yup.AnySchema> = {
  city,
  contractNumber: Yup.string()
    .matches(
      /^(VN-\d{8}|3\d{8})$/,
      'Ungültige Vertragsnummer. Beginnend mit “VN-” oder “3".'
    )
    .required('Bitte füllen Sie dieses Feld aus.'),

  customerNumber: Yup.string()
    .matches(/^QC-\d{7,8}$/, 'Ungültige Kundennummer. Beginnend mit “QC-“.')
    .required('Bitte füllen Sie dieses Feld aus.'),
  email,
  firstName,
  lastName,
  street,
  streetNumber,
  zipCode: zipcode,
  agreement: Yup.mixed().test(
    'is-true',
    'Wir benötigen Ihre Zustimmung.',
    // @ts-ignore
    (value: boolean | string) => value === true
  ),
};

export default schema;
