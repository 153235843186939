import { getSafeInnerHTML } from '@/helpers/getSafeInnerHTML';
import { setAttrsInExternalAnchors } from '@uikit/helpers/setAttrsInExternalAnchors';
import clsx from 'clsx';
import Clickable from '../Clickable/Clickable';
import Picture from '../Picture/Picture';
import styles from './TeaserImageCard.module.scss';
import type {
  TeaserHeadlineTag,
  TeaserImageCardFooterProps,
  TeaserImageCardHeadlineProps,
  TeaserImageCardProps,
  TeaserImageCardTextProps,
} from './types';

const TeaserImageCardHeadline = <HeadlineTag extends TeaserHeadlineTag>(
  props: TeaserImageCardHeadlineProps<HeadlineTag>
) => {
  const { tag: Tag = 'h4', children, className, ...restProps } = props;

  return (
    <Tag {...restProps} className={clsx(styles.headline, className)}>
      {children}
    </Tag>
  );
};

const TeaserImageCardText = ({
  children,
  className,
  ...restProps
}: TeaserImageCardTextProps) => {
  const props = {
    className: clsx(styles.text, className),
    ...restProps,
  };

  if (typeof children !== 'string') {
    return <div {...props}>{children}</div>;
  }

  if (children.search('\\>') > -1) {
    const content = children?.replaceAll('`', '\\`');

    return (
      <div
        {...props}
        dangerouslySetInnerHTML={{
          __html: setAttrsInExternalAnchors(getSafeInnerHTML(content)),
        }}
      />
    );
  }

  return (
    <div {...props}>
      <p>{children}</p>
    </div>
  );
};

const TeaserImageCardFooter = (props: TeaserImageCardFooterProps) => (
  <div {...props} className={clsx(styles.footer, props.className)} />
);

const TeaserImageCard = ({
  children,
  className,
  disableTransition = false,
  image,
  imageAspectRatio = '3 / 2',
  layout = 'column',
  maxResponsiveSize = 'm',
  ...props
}: TeaserImageCardProps) => {
  const baseContent = (
    <>
      <Picture
        asset={image}
        aspectRatio={imageAspectRatio}
        className={styles.image}
        objectFit="cover"
      />
      <div
        className={clsx(styles.content, {
          [styles.maxSizeS]: maxResponsiveSize === 's',
          [styles.maxSizeM]: maxResponsiveSize === 'm',
          [styles.maxSizeL]: maxResponsiveSize === 'l',
        })}
      >
        {children}
      </div>
    </>
  );

  const baseProps = {
    className: clsx(styles.base, className, {
      [styles.row]: layout === 'row',
      [styles.hoverTransition]: !props.deactivateLink && !disableTransition,
    }),
  };

  return props.deactivateLink ? (
    <div {...baseProps}>{baseContent}</div>
  ) : (
    <Clickable {...props} {...baseProps}>
      {baseContent}
    </Clickable>
  );
};

TeaserImageCard.Headline = TeaserImageCardHeadline;
TeaserImageCard.Text = TeaserImageCardText;
TeaserImageCard.Footer = TeaserImageCardFooter;

export default TeaserImageCard;
