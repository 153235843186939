import { getSafeInnerHTMLProp } from '@/helpers/getSafeInnerHTML';
import clsx from 'clsx';
import parseCustomMarkdown from '../../helpers/parseCustomMarkdown';
import styles from './Table.module.scss';
import findAdditionalHeadline from './findAdditionalHeadline';
import type { TableProps } from './types';

const Table = ({
  tableData,
  layout = 'auto',
  color = 'blue',
  alternateColumns = false,
}: TableProps) => {
  const convert = (value: string) =>
    parseCustomMarkdown(value.replace('#titel', ''));

  const hasAdditionalHeadline = findAdditionalHeadline(tableData);
  const hasHeadColumn =
    typeof tableData.body[0][0] !== 'string' &&
    tableData.body[0][0].type === 'th';

  const className = clsx(styles.base, styles[color], {
    [styles.hasAdditionalHeadline]: hasAdditionalHeadline,
    [styles.hasHeadColumn]: hasHeadColumn,
  });

  return (
    <div className={className}>
      <table className={styles[layout]}>
        {tableData.caption && <caption>{tableData.caption}</caption>}
        {!!tableData.head?.length && (
          <thead>
            <tr
              className={clsx({
                [styles.additionalHeadlineRow]:
                  tableData.head[0]?.includes('#titel'),
                [styles.alternateColumns]: alternateColumns,
              })}
            >
              {tableData.head.map((th, idx) => (
                <th key={idx} {...getSafeInnerHTMLProp(convert(th))} />
              ))}
            </tr>
          </thead>
        )}
        {tableData.body && (
          <tbody>
            {tableData.body.map((_tr, idx) => {
              const tr = _tr.map((td) =>
                typeof td === 'string'
                  ? { content: td, type: 'td' as 'td' }
                  : td
              );
              return (
                <tr
                  key={idx}
                  className={clsx({
                    [styles.additionalHeadlineRow]:
                      tr[0].content.includes('#titel'),
                    [styles.alternateColumns]: alternateColumns,
                  })}
                >
                  {tr.map((el, jdx) => (
                    <el.type key={jdx}>
                      <div {...getSafeInnerHTMLProp(convert(el.content))} />
                    </el.type>
                  ))}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default Table;
