import { ASSET_BASE_URL } from '../../../config';
import { LocationCategory, LocationType } from './types';

export const ZOOM_DEFAULT = 11;

export const CENTER_DEFAULT = {
  lat: 52.5457809,
  lng: 9.7336445,
};

export const BOOTSTRAP_URL_KEYS = {
  libraries: ['places', 'geometry'],
  language: 'ge',
};

export const INIT_MAP_STATE = {
  mapApiLoaded: false,
  mapInstance: null,
  mapApi: null,
};

export const BOUNDS_GERMANY = {
  sw: { lat: 47.3024876979, lng: 5.98865807458 },
  ne: { lat: 54.983104153, lng: 15.0169958839 },
};

export const OPTIONS = {
  fullscreenControl: false,
  gestureHandling: 'cooperative',
  mapTypeControl: true,
  mapTypeControlOptions: { position: 3 },
  zoomControlOptions: { position: 7 },
  scrollwheel: false,
  styles: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'water',
      stylers: [{ saturation: -90 }],
    },
    {
      featureType: 'landscape.man_made',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'landscape.natural',
      stylers: [{ saturation: -90 }, { gamma: 7 }],
    },
    {
      featureType: 'road',
      stylers: [{ saturation: -100 }, { gamma: 2.29 }],
    },
    {
      featureType: 'transit',
      elementType: 'labels.text',
      stylers: [{ saturation: -100 }, { gamma: 2.29 }],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{ saturation: -100 }, { gamma: 0.5 }],
    },
  ],
};

export const WEDEMARK_MAP_DATA_PATH = `${ASSET_BASE_URL}/assets/wedemark-map-data/data.json`;

export const POLYGON_STYLES = {
  landscapeprotection: {
    fillColor: '#64B32C',
    strokeWeight: '0',
  },
  residential: {
    fillColor: '#fce2cc',
    strokeColor: '#ee7100',
  },
  waterprotection: {
    fillColor: '#1750B5',
    strokeWeight: '0',
    fillOpacity: '0.8',
  },
  planned: {
    fillColor: '#ebcceb',
    strokeColor: '#990099',
    zIndex: '1',
  },
  naturereserve: {
    fillColor: '#FFC500',
    strokeWeight: '0',
    fillOpacity: '0.6',
    zIndex: '1',
  },
  forest: {
    fillColor: '#64B32C',
    fillOpacity: '0.8',
    strokeWeight: '0',
  },
  existing: {
    fillColor: '#CCEBED',
    strokeColor: '#007E85',
  },
  hidden: {
    fillColor: 'transparent',
    strokeColor: 'transparent',
  },
};

export const LOCATION_TYPES: LocationType[] = [
  'waterprotection',
  'planned',
  'landscapeprotection',
  'residential',
  'existing',
  'forest',
  'naturereserve',
];

export const CATEGORY_TO_LOCATION_TYPE: Record<LocationCategory, LocationType> =
  {
    Wasserschutzgeb: 'waterprotection',
    Windparkflaeche: 'planned',
    LSG: 'landscapeprotection',
    Abstandsflaeche: 'residential',
    Bestandspark: 'existing',
    'Historischer Waldstandort': 'forest',
    NSG: 'naturereserve',
  };
