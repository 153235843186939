import delay from '@/helpers/delay';
import submitFormApi from '@/helpers/submitFormApi';
import { UseFormState } from '@/hooks/useForm/interfaces';
import useForm from '@/hooks/useForm/useForm';
import useFrcCaptcha from '@/hooks/useFrcCaptcha/useFrcCaptcha';
import ButtonSubmit from '@uikit/components/ButtonSubmit/ButtonSubmit';
import { ButtonSubmitState } from '@uikit/components/ButtonSubmit/consts';
import CheckBoxBlock from '@uikit/components/CheckBoxBlock/CheckBoxBlock';
import FormField from '@uikit/components/FormField/FormField';
import FormInfoBox from '@uikit/components/FormInfoBox/FormInfoBox';
import FormRow from '@uikit/components/FormRow/FormRow';
import { useState } from 'react';
import { FormComponentProps } from '../interfaces';
import schema from './schema';
import { QCellsApprovalFormValues } from './types';

const initialFormValues: QCellsApprovalFormValues = {
  city: '',
  contractNumber: '',
  customerNumber: '',
  email: '',
  firstName: '',
  lastName: '',
  street: '',
  streetNumber: '',
  zipCode: '',
  agreement: false,
};

const QCellsApprovalForm = ({
  onSuccess,
  buttonLabel = 'Zustimmung absenden',
  dataProcessing,
}: FormComponentProps): JSX.Element => {
  const [buttonState, setButtonState] = useState(ButtonSubmitState.Initial);
  const { getSolution, FrcCaptchaComponent } = useFrcCaptcha();

  const callbackOnSubmit = async (formState: UseFormState) => {
    setButtonState(ButtonSubmitState.Pending);

    try {
      const frcCaptchaSolution = await getSolution();

      const response = await submitFormApi<QCellsApprovalFormValues>({
        formId: 'qCellsApproval',
        formFields: {
          city: formState['city'],
          contractNumber: formState['contractNumber'],
          customerNumber: formState['customerNumber'],
          email: formState['email'],
          firstName: formState['firstName'],
          lastName: formState['lastName'],
          street: formState['street'],
          streetNumber: formState['streetNumber'],
          zipCode: formState['zipCode'],
          agreement: formState['agreement'],
        },
        frcCaptchaSolution,
      });
      const json = await response.json();

      if (json.message === 'success') {
        setButtonState(ButtonSubmitState.Success);
        await delay(1500);
        onSuccess && onSuccess();
      } else {
        throw Error;
      }
    } catch (error) {
      setButtonState(ButtonSubmitState.Fail);
      await delay(2000);
      setButtonState(ButtonSubmitState.Initial);
    }
  };
  const {
    formState,
    getErrorByFieldName,
    isFormValid,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useForm(callbackOnSubmit, schema, initialFormValues, 'qCellsApproval');

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <FormField
          onBlur={handleBlur}
          onChange={handleChange}
          name="firstName"
          value={formState['firstName']}
          errorMessage={getErrorByFieldName('firstName')}
          label="Vorname"
          autoComplete="given-name"
          id="first-name"
        />
        <FormField
          onBlur={handleBlur}
          onChange={handleChange}
          name="lastName"
          value={formState['lastName']}
          errorMessage={getErrorByFieldName('lastName')}
          label="Nachname"
          autoComplete="family-name"
          id="last-name"
        />
      </FormRow>
      <FormRow>
        <FormField
          onBlur={handleBlur}
          onChange={handleChange}
          name="customerNumber"
          value={formState['customerNumber']}
          errorMessage={getErrorByFieldName('customerNumber')}
          label="Kundennummer"
          id="customer-number"
        />
      </FormRow>
      <FormRow>
        <FormField
          onBlur={handleBlur}
          onChange={handleChange}
          name="contractNumber"
          value={formState['contractNumber']}
          errorMessage={getErrorByFieldName('contractNumber')}
          label="Vertragsnummer"
          id="contract-number"
        />
      </FormRow>
      <FormRow>
        <FormField
          onBlur={handleBlur}
          onChange={handleChange}
          name="street"
          value={formState['street']}
          errorMessage={getErrorByFieldName('street')}
          label="Straße"
          autoComplete="address-line1"
          id="street"
        />
        <FormField
          onBlur={handleBlur}
          onChange={handleChange}
          name="streetNumber"
          value={formState['streetNumber']}
          errorMessage={getErrorByFieldName('streetNumber')}
          label="Hausnummer"
          autoComplete="address-line2"
          halfWidth
          id="street-number"
        />
      </FormRow>
      <FormRow>
        <FormField
          maxLength={5}
          onBlur={handleBlur}
          onChange={handleChange}
          name="zipCode"
          value={formState['zipCode']}
          errorMessage={getErrorByFieldName('zipCode')}
          label="Postleitzahl"
          autoComplete="postal-code"
          halfWidth
          id="zipcode"
        />
        <FormField
          onBlur={handleBlur}
          onChange={handleChange}
          name="city"
          value={formState['city']}
          errorMessage={getErrorByFieldName('city')}
          label="Ort"
          autoComplete="address-level2"
          id="city"
        />
      </FormRow>
      <FormRow>
        <FormField
          onBlur={handleBlur}
          onChange={handleChange}
          name="email"
          value={formState['email']}
          errorMessage={getErrorByFieldName('email')}
          label="E-Mail-Adresse"
          autoComplete="email"
          id="email"
        />
      </FormRow>

      <FormRow>
        <CheckBoxBlock
          onChange={handleChange}
          errorMessage={getErrorByFieldName('agreement')}
          checked={formState['agreement']}
          name="agreement"
        >
          Ich stimme der Vertragsübernahme durch die enercity AG sowie
          einhergehend der Übermittlung und Verarbeitung meiner Kundendaten, der
          Übertragung des SEPA-Mandates und dem im Anschreiben bezeichneten
          Tarif mit Preisen und Laufzeiten zu.
        </CheckBoxBlock>
      </FormRow>

      <FormRow>
        <FormInfoBox>{dataProcessing}</FormInfoBox>
      </FormRow>

      <FormRow fullWidthChildren={false} bottomMarginSize={{ default: 1.125 }}>
        <ButtonSubmit inactive={!isFormValid} state={buttonState}>
          {buttonLabel}
        </ButtonSubmit>
      </FormRow>

      <FrcCaptchaComponent />
    </form>
  );
};

export default QCellsApprovalForm;
