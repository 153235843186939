import { useRef, useState } from 'react';
import Button from '../Button/Button';
import { ClickableAction, ClickableTarget } from '../Clickable/consts';
import type { FormFieldElement } from '../FormField/interfaces';
import FormFieldSuggestion from '../FormFieldSuggestion/FormFieldSuggestion';
import type { SuggestionOption } from '../FormFieldSuggestion/interfaces';
import {
  filterOptions,
  getBrandOptions,
  getModelOptions,
  shouldBeSelected,
} from './helpers';
import styles from './SmartChargingBetaAppSelector.module.scss';

const initialState = {
  brand: {
    fieldValue: '',
    selected: '',
  },
  model: {
    fieldValue: '',
    selected: '',
  },
};

type State = typeof initialState;

const SmartChargingBetaAppSelector = () => {
  const brandFieldRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<State>(initialState);
  const brandOptions = getBrandOptions();
  const modelOptions = state.brand.selected
    ? getModelOptions(state.brand.selected)
    : [];

  const brandSuggestions = !state.brand.selected
    ? filterOptions(state.brand.fieldValue, brandOptions)
    : [];

  const modelsSuggetions = !state.model.selected
    ? filterOptions(state.model.fieldValue, modelOptions)
    : [];

  const onChange = ({ target }: React.ChangeEvent<FormFieldElement>) => {
    const { value, name } = target;

    setState((state) => {
      if (name === 'brand') {
        return {
          ...initialState,
          brand: {
            fieldValue: value,
            selected: shouldBeSelected(value, brandOptions) ? value : '',
          },
        };
      }

      if (name === 'model') {
        return {
          ...state,
          model: {
            fieldValue: value,
            selected: shouldBeSelected(value, modelOptions) ? value : '',
          },
        };
      }

      return state;
    });
  };

  const onSelectBrandOption =
    (name: keyof State) => (option: SuggestionOption) => {
      setState((state) => ({
        ...state,
        [name]: {
          fieldValue: option.value,
          selected: option.value,
        },
      }));
    };

  const shouldShowButtons = !!state.brand.selected && !!state.model.selected;

  return (
    <div className={styles.base}>
      <div className={styles.fields}>
        <FormFieldSuggestion
          ref={brandFieldRef}
          inputId="car-brand"
          label="Hersteller Ihres Fahrzeugs"
          options={brandSuggestions}
          nativeOptions={brandSuggestions}
          onChange={onChange}
          value={state.brand.fieldValue}
          name="brand"
          onSelectOption={onSelectBrandOption('brand')}
          disableNativeSelect
        />
        {modelOptions.length > 0 && (
          <FormFieldSuggestion
            inputId="car-model"
            label="Modell Ihres Fahrzeugs"
            options={modelsSuggetions}
            nativeOptions={modelsSuggetions}
            onChange={onChange}
            name="model"
            value={state.model.fieldValue}
            onSelectOption={onSelectBrandOption('model')}
            autoFocus
            disableNativeSelect
          />
        )}
      </div>
      {shouldShowButtons && (
        <div className={styles.buttons}>
          <Button
            actionType={ClickableAction.Linkextern}
            linkextern="https://testflight.apple.com/join/evFCWeHD"
            target={ClickableTarget.Blank}
          >
            mit iOS (Apple) testen
          </Button>
          <Button
            actionType={ClickableAction.Linkextern}
            linkextern="https://play.google.com/store/apps/details?id=com.enercity.smartcharging"
            target={ClickableTarget.Blank}
          >
            mit Android testen
          </Button>
        </div>
      )}
    </div>
  );
};

export default SmartChargingBetaAppSelector;
