import clsx from 'clsx';
import type { NativeSelectProps } from './interfaces';
import styles from './NativeSelect.module.scss';

const NativeSelect = ({
  className,
  options,
  selectedOption,
  mobileOnly = true,
  ariaLabelledBy,
  ...props
}: NativeSelectProps) => {
  return (
    <select
      className={clsx(
        styles.nativeSelect,
        { [styles.nativeSelectMobileOnly]: mobileOnly },
        className
      )}
      value={
        options.length && selectedOption
          ? selectedOption.value || selectedOption.label
          : ''
      }
      aria-labelledby={ariaLabelledBy}
      {...props}
    >
      <option value="" disabled>
        Bitte wählen
      </option>
      {options.map((option, idx) => (
        <option key={idx} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default NativeSelect;
