import LinkButton from '@uikit/components/LinkButton/LinkButton';
import TeaserImageCard from '@uikit/components/TeaserImageCard/TeaserImageCard';
import { TeaserImageCardSmallWrapperProps } from './types';

const TeaserImageCardSmallWrapper = ({
  headline,
  headlineTag = 'h3',
  linkLabel,
  text,
  ...props
}: TeaserImageCardSmallWrapperProps) => {
  return (
    <TeaserImageCard
      {...props}
      maxResponsiveSize="m"
      imageAspectRatio={{ initial: '3 / 2', xs: '7 / 4' }}
    >
      <TeaserImageCard.Headline tag={headlineTag}>
        {headline}
      </TeaserImageCard.Headline>
      {text && <TeaserImageCard.Text>{text}</TeaserImageCard.Text>}
      {linkLabel && !props.deactivateLink && (
        <TeaserImageCard.Footer>
          <LinkButton
            align="left"
            as="div"
            disableTransition
            icon="action/arrow-right"
            iconPosition="hidden"
            size="large"
            variant="default"
          >
            {linkLabel}
          </LinkButton>
        </TeaserImageCard.Footer>
      )}
    </TeaserImageCard>
  );
};

export default TeaserImageCardSmallWrapper;
