import type { PropsWithChildren } from 'react';
import Icon from '../Icon/Icon';
import type { IconVariants } from '../Icon/interfaces';
import styles from './FlipCard.module.scss';
import type { FlipCardHeadlineProps, FlipCardProps } from './types';

const FlipCard = ({ children }: FlipCardProps) => {
  return (
    <label className={styles.base}>
      <input type="checkbox" className={styles.checkbox} />
      {children}
    </label>
  );
};

const FlipCardFront = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles.front}>
      <span className={styles.actionIcon}>
        <Icon size="iconSize32" variant="action/arrow-right" />
      </span>

      {children}
    </div>
  );
};

const FlipCardBack = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles.back}>
      <Icon
        size="iconSize32"
        variant="action/close"
        className={styles.actionIcon}
      />

      {children}
    </div>
  );
};

const FlipCardHeadline = ({
  children,
  tag: Tag = 'h3',
}: FlipCardHeadlineProps) => {
  return <Tag className={styles.headline}>{children}</Tag>;
};

const FlipCardIcon = ({ icon }: { icon: IconVariants }) => {
  return (
    <Icon className={styles.icon} size="iconSize64" variant={icon} isOutlined />
  );
};

export default Object.assign(FlipCard, {
  Front: FlipCardFront,
  Back: FlipCardBack,
  Headline: FlipCardHeadline,
  Icon: FlipCardIcon,
});
