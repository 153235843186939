import riveWASMResource from '@rive-app/canvas/rive.wasm?url';
import { RuntimeLoader, useRive } from '@rive-app/react-canvas';
import type { RiveWrapperProps } from './types';

RuntimeLoader.setWasmUrl(riveWASMResource);

const createRiveParams = (props: RiveWrapperProps) => {
  if ('asset' in props) {
    const { asset, ...riveParams } = props;
    return {
      ...riveParams,
      src: asset.path,
    };
  }

  return props;
};

const RiveWrapper = ({ className, ...props }: RiveWrapperProps) => {
  const { RiveComponent } = useRive(
    {
      autoplay: true,
      ...createRiveParams(props),
    },
    {
      fitCanvasToArtboardHeight: true,
    }
  );

  return <RiveComponent className={className} />;
};

export default RiveWrapper;
