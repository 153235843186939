import clsx from 'clsx';
import { useRef } from 'react';
import Lottie from '../Lottie/Lottie';
import styles from './LottieWrapper.module.scss';
import type { LottieWrapperProps } from './interfaces';
import { useLottieAsset } from './useLottieAsset';

const LottieWrapper = ({
  animationName,
  animationData,
  animationAsset,
  startInView = false,
  loop = true,
  align = 'left',
  noScale,
  className,
}: LottieWrapperProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { data: animationAssetData, isLoading } =
    useLottieAsset(animationAsset);

  if (!animationData && !animationAsset?.path) {
    return null;
  }

  return (
    <div
      ref={wrapperRef}
      className={clsx(styles.main, className, styles[align], {
        [styles.noScale]: noScale,
      })}
    >
      {isLoading ? (
        'Lade Animation...'
      ) : (
        <Lottie
          autoplay={!startInView}
          loop={loop}
          playing={!startInView}
          startInView={startInView}
          name={animationName}
          animationData={
            animationAssetData ||
            (animationData ? JSON.parse(animationData) : animationAssetData)
          }
          parentRef={wrapperRef}
        />
      )}
    </div>
  );
};

export default LottieWrapper;
